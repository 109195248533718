import "./App.css";
import calendar from "./images/calendar-icon.png";
import profile from "./images/profile-icon.png";
import country from "./images/country-icon.png";
import email from "./images/email-icon.png";
import form_filling_heading from "./images/form-filling-heading.png";
import form_filling_image from "./images/form-filling-image.svg";
import React, { useRef, useState, useContext, useEffect } from "react";
function App() {
  const ref = useRef();
  const base_url = process.env.REACT_APP_API_URI;
  const [isLoggedIn, setIsLoggedIn] = useState(""); // for checking if the user has already added his information or not.
  const [state, setState] = useState({
    name: "",
    phone: "",
    email: "",
    date_of_birth: "",
    correct_score: 1,
    vbs: 1,
  }); // for form filling.
  const update = (event) => {
    const target = event.currentTarget;

    setState({
      ...state,
      [target.name]: target.type === "checkbox" ? target.checked : target.value,
    });
  };
  // Token verification whether the user has filled the form or not
  useEffect(() => {
    const token_verification = async () => {
      let token = sessionStorage.getItem("formSubmission"); // get token
      if (!token || token === undefined || token === "") {
        // check if the token is present or not if no then dont do anything
        setIsLoggedIn(false);
      } else {
        // else send a request to the route and validate whether the token is valid or not

        setIsLoggedIn(true);
        // disable fields and pop up appear
        console.log("already submitted");
      }
    };
    token_verification();
    // Until here
  }, [isLoggedIn]);
  const handleLogin = async (event) => {
    event.preventDefault();
    if (isLoggedIn) {
      console.log("Already submitted");
    } else {
      let bodyFormData = new FormData();
      bodyFormData.append("name", state.name);
      bodyFormData.append("email", state.email);
      bodyFormData.append("phone", state.phone);
      bodyFormData.append("date_of_birth", state.date_of_birth);
      bodyFormData.append("correct_score", true);
      bodyFormData.append("vbs", true);
      // console.log(state.date_of_birth);
      try {
        let res = await fetch(`${base_url}/add_user`, {
          method: "POST",
          body: bodyFormData,
        }).catch((err) => {
          console.log(err);
        });
        // We will get  the access token cookie and refresh token cookie and it will be stored in react page

        const response = await res.json();

        if (!res.ok) {
          console.log(response.msg);
          // failed to logged in show in form
        } else {
          sessionStorage.setItem("formSubmission", "yes");
          setIsLoggedIn(true);

          // Redirect to dashboard
          //
          console.log(response.message);
        }
      } catch (err) {
        // console.log(err.status);
      }
    }
  };
  return (
    <section className="form-filling-box my-5 relative">
      {isLoggedIn == true ? (
        <div>
          <div
            className="row w-100"
            style={{ position: "absolute", top: "-25px" }}
          >
            <div className="col-12 d-flex justify-content-center">
              <img src={form_filling_heading} width="200px" alt="" />
            </div>
          </div>
          <form className="pt-3">
            <div className="form-row p-3">
              <div className="col-12">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div
                      className="input-group-text"
                      style={{
                        borderTopRightRadius: "0.25rem",
                        borderBottomRightRadius: "0.25rem",
                        borderTopLeftRadius: "0",
                        borderBottomLeftRadius: "0",
                      }}
                    >
                      <img
                        src={profile}
                        className="form-filling-icon"
                        alt=""
                        srcSet=""
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="الإسم"
                    disabled
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div
                      className="input-group-text"
                      style={{
                        borderTopRightRadius: "0.25rem",
                        borderBottomRightRadius: "0.25rem",
                        borderTopLeftRadius: "0",
                        borderBottomLeftRadius: "0",
                      }}
                    >
                      <img
                        src={country}
                        className="form-filling-icon"
                        alt=""
                        srcSet=""
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    name="phone"
                    placeholder="رقم الموبايل"
                    disabled
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div
                      className="input-group-text"
                      style={{
                        borderTopRightRadius: "0.25rem",
                        borderBottomRightRadius: "0.25rem",
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                    >
                      <img
                        src={email}
                        className="form-filling-icon"
                        alt=""
                        srcSet=""
                      />
                    </div>
                  </div>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="البريد الإلكتروني"
                    disabled
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div
                      className="input-group-text"
                      style={{
                        borderTopRightRadius: "0.25rem",
                        borderBottomRightRadius: "0.25rem",
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                    >
                      <img
                        src={calendar}
                        className="form-filling-icon"
                        alt=""
                        srcSet=""
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    ref={ref}
                    name="date_of_birth"
                    id="date_of_birth"
                    className="form-control"
                    placeholder="سنة الميلاد"
                    onFocus={() => {
                      if (ref.current) {
                        ref.current.type = "date";
                      }
                    }}
                    onBlur={() => {
                      if (ref.current) {
                        ref.current.type =
                          ref.current.type == "date" ? "text" : "date";
                      }
                    }}
                    disabled
                  />
                </div>
              </div>

              <div className="col-12">
                <button
                  className="btn bg-success text-light  mb-2 w-100"
                  disabled
                >
                  تم تأكيد الإشتراك
                </button>
              </div>
            </div>
          </form>
          <img src={form_filling_image} alt="" srcSet="" />
        </div>
      ) : (
        <div>
          <div
            className="row w-100"
            style={{ position: "absolute", top: "-25px" }}
          >
            <div className="col-12 d-flex justify-content-center">
              <img src={form_filling_heading} width="200px" alt="" />
            </div>
          </div>
          <form className="pt-3">
            <div className="form-row p-3">
              <div className="col-12">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div
                      className="input-group-text"
                      style={{
                        borderTopRightRadius: "0.25rem",
                        borderBottomRightRadius: "0.25rem",
                        borderTopLeftRadius: "0",
                        borderBottomLeftRadius: "0",
                      }}
                    >
                      <img
                        src={profile}
                        className="form-filling-icon"
                        alt=""
                        srcSet=""
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="الإسم"
                    onChange={update}
                    required
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div
                      className="input-group-text"
                      style={{
                        borderTopRightRadius: "0.25rem",
                        borderBottomRightRadius: "0.25rem",
                        borderTopLeftRadius: "0",
                        borderBottomLeftRadius: "0",
                      }}
                    >
                      <img
                        src={country}
                        className="form-filling-icon"
                        alt=""
                        srcSet=""
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    name="phone"
                    onChange={update}
                    placeholder="رقم الموبايل"
                    required
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div
                      className="input-group-text"
                      style={{
                        borderTopRightRadius: "0.25rem",
                        borderBottomRightRadius: "0.25rem",
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                    >
                      <img
                        src={email}
                        className="form-filling-icon"
                        alt=""
                        srcSet=""
                      />
                    </div>
                  </div>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    onChange={update}
                    placeholder="البريد الإلكتروني"
                    required
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div
                      className="input-group-text"
                      style={{
                        borderTopRightRadius: "0.25rem",
                        borderBottomRightRadius: "0.25rem",
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                    >
                      <img
                        src={calendar}
                        className="form-filling-icon"
                        alt=""
                        srcSet=""
                      />
                    </div>
                  </div>
                  <input
                    type="text"
                    ref={ref}
                    className="form-control"
                    placeholder="سنة الميلاد"
                    name="date_of_birth"
                    id="date_of_birth"
                    onChange={update}
                    onFocus={() => {
                      if (ref.current) {
                        ref.current.type = "date";
                      }
                    }}
                    onBlur={() => {
                      if (ref.current) {
                        ref.current.type =
                          ref.current.type == "date" ? "text" : "date";
                      }
                    }}
                  />
                </div>
              </div>

              <div className="col-12">
                <button
                  className="btn custom-button-form mb-2 w-100"
                  onClick={handleLogin}
                >
                  تأكيد الاشتراك
                </button>
              </div>
            </div>
          </form>
          <img src={form_filling_image} alt="" srcSet="" />
        </div>
      )}
    </section>
  );
}

export default App;
